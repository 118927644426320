import { Component, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { environment } from '@environment/environment';
import { filter, Subject, takeUntil } from 'rxjs';
import { LoginService } from './core/services';

@Component({
  selector: 'howden-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private logingSrv: LoginService) {
    this.authService.handleRedirectObservable().subscribe();
    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.authService.instance.enableAccountStorageEvents();
    // Set the active account off
    this.authService.instance.setActiveAccount(null);

    this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED
        || msg.eventType === EventType.ACCOUNT_REMOVED),
      takeUntil(this._destroying$)
    ).subscribe(() => {
      if (this.authService.instance.getAllAccounts().length === 0) {
        window.location.pathname = '/';
      }
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE
          || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE)
      ).subscribe((result: EventMessage) => {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error) {
          if (result.error.message.indexOf('AADB2C90118') > -1) {
            const resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
              authority: environment.openid!.policies!.authorities.resetPassword.authority,
              scopes: [...environment.openid!.apiConfig.scopes]
            };

            this.logingSrv.loginRedirect(resetPasswordFlowRequest);
          } else if (result.error.message.indexOf('AADB2C90091') > -1) {
            this.logingSrv.loginRedirect();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
