const howdenGroupMicrosoftEntraConfig = {
  msalConfig: {
    auth: {
      clientId: '797161ca-34c3-412c-9ee4-c3c4996b54c3',
      authority: 'https://login.microsoftonline.com/organizations',
      redirectUri: () => `${location.origin}/granted`,
      postLogoutRedirectUri: () => `${location.origin}/logout`
    }
  },
  apiConfig: {
    clientId: '2790d7fc-7085-428e-842c-14cf69c77d6d',
    scopes: ['api://2790d7fc-7085-428e-842c-14cf69c77d6d/user_impersonations']
  },
  protectedResources: new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    [`${location.origin}`, ['api://2790d7fc-7085-428e-842c-14cf69c77d6d/user_impersonations']]
  ])
};

export { howdenGroupMicrosoftEntraConfig };
