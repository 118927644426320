import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EvaluationsService {
  stringIsNullOrEmpty(value: string): boolean {
    return !(!!value && value !== '' && value.length > 0);
  }

  dateValidNow(date: Date | null | undefined): boolean {
    if (!date) {
      return false;
    }

    const now = moment().toDate();
    const date2 = new Date(date.toString());
    const res = date2.getTime() - now.getTime();

    return res > 0;
  }
}
