import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user/user.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'howden-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {
  account?: string = '';

  constructor(
    private readonly usrSrv: UserService,
    private readonly router: Router,
    private authService: MsalService
  ) {
  }

  ngOnInit(): void {
    this.account = this.authService.instance.getActiveAccount()?.username;
    this.authService.instance.setActiveAccount(null);
  }

  retry() {
    this.usrSrv.clearIDData();
    const callBack = this.usrSrv.appData.callback ? `?callback=${this.usrSrv.appData.callback}` : '';
    if (this.usrSrv.appData.isInternal) {
      this.router.navigateByUrl(`login-dir${callBack}`);
    } else {
      this.router.navigateByUrl(`external-login${callBack}`);
    }
  }
}
