import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import packageJson from 'package.json';

@Injectable({
  providedIn: 'root'
})
export class HowdenInformation {
  private readonly _version: string = packageJson.version;
  private readonly _appTitle: string = packageJson.name;

  get version(): string {
    return `${this._version}`;
  }

  get name(): string {
    return `${this._appTitle}`;
  }

  get nameNormalized(): string {
    const norm = this._appTitle.replace(/\s/g, '');
    return `${norm}`;
  }

  get titleVersion(): string {
    return `${this.name} ${this.version}`;
  }

  get title(): string {
    return `${this.name}`;
  }

  get enviroment(): string {
    return environment.zone.toString();
  }
}
