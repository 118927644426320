import { EnviromentZone, IHowdenEnvironment, LogLevel } from '@howdeniberia/core-front';
import { howdenGroupMicrosoftEntraConfig } from './microsoft-entra-howden-group';

export const environment = {
  zone: EnviromentZone.DEVHOWDENGROUP,
  appIdSecurity: 0,
  emailError: 'desarrollo@howdeniberia.onmicrosoft.com',
  logLevel: LogLevel.VERBOSE,
  openid: howdenGroupMicrosoftEntraConfig
} as IHowdenEnvironment;
