import { Component, OnInit } from '@angular/core';
import { ActionsService, UserService } from '@app/core/services';

@Component({
  selector: 'howden-sucess',
  templateUrl: './sucess.component.html',
  styleUrls: ['./sucess.component.scss']
})
export class SucessComponent implements OnInit {
  constructor(private usrSrv: UserService, private actionsSrv: ActionsService) {
  }

  ngOnInit(): void {
    this.actionsSrv.hideLoading();
  }

  goCallBack() {
    if (this.usrSrv.validCallBack) {
      window.location.href = this.usrSrv.callBackStr;
    }
  }

  get validUrl(): boolean {
    return this.usrSrv.validCallBack;
  }
}
