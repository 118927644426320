import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private _errorMsg = '';

  constructor(private route: Router) {
  }

  showError(msg: string) {
    this.setErrorMsg(msg);
    this.route.navigateByUrl('/err');
  }

  setErrorMsg(msg: string) {
    this._errorMsg = msg;
  }

  get errorMsg(): string {
    return this._errorMsg;
  }
}
