<div class="error-page">
  <div class="flex error-container f-fd--column-md f-gap--25px f-jc--center f-ai--center">
    <div class="error-code text-right">
      <h1>OK</h1>
    </div>
    <div class="error-text text-left">
      <h2 i18n="@@front-login.logout.title">Proceso de cierre de sesión completado</h2>
      <br />
      @if (seeUrl) {
        <div>
          @if (this.appName === '') {
            <p i18n="@@front-login.logout.body.return-app-generic">
              Si quiere acceder de nuevo a la aplicación, pulse <a href="{{ this.callbackUrl }}">aquí</a>
            </p>
          } @else {
            <p i18n="@@front-login.logout.body.return-app">
              Si quiere acceder de nuevo a <b>{{ this.appName }}</b>, pulse <a href="{{ this.callbackUrl }}">aquí</a>
            </p>
          }
        </div>
      } @else {
        <div>
          <p i18n="@@front-login.forbidden.close-message">Puedes cerrar esta ventana</p>
        </div>
      }
    </div>
  </div>
</div>
