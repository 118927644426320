@if (finished) {
  <div class="flex f-fd--column f-jc--center f-ai--center">
    <mat-card style="width: 800px;">
      <mat-card-header>
        <mat-card-title i18n-placeholder="@@front-login.result.title">Resultado {{ envName }}</mat-card-title>
        <mat-card-subtitle i18n-placeholder="@@front-login.result.subtitle">Información del Token</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <div class="flex f-fd--column f-gap--25px f-jc--center base">
          <div class="flex">
            <div class="f-basis-12">authData Result</div>
          </div>

          <div class="flex">
            <div class="f-basis-12">
              <div i18n-placeholder="@@front-login.result.user">user: {{ authData?.name }}</div>
            </div>
          </div>

          <div class="flex">
            <div class="texto">tokenAD: {{ authData?.tokenAD }}</div>
            <button mat-icon-button color="primary" (click)="copy(authData?.tokenAD)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>

          <div class="flex">
            <span i18n-placeholder="@@front-login.result.tokenAD.expiration">
              tokenAD válido hasta: {{ authData?.tokenADValidDate | date : "yyyy/MM/dd hh:mm a" }}
            </span>
          </div>

          <div class="flex">
            <div class="texto">
              tokenID: {{ authData?.tokenID }}
            </div>
            <button mat-icon-button color="primary" (click)="copy(authData?.tokenID)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>

          <span i18n-placeholder="@@front-login.result.tokenID.expiration">
            tokenID válido hasta: {{ authData?.tokenIDValidDate | date : "yyyy/MM/dd hh:mm a" }}
          </span>

          <mat-divider></mat-divider>

          <div class="flex">
            <div class="texto" i18n-placeholder="@@front-login.result.tokenID.refresh">
              tokenID refresh: {{ authData?.tokenIDRefresh }}
            </div>
            <button mat-icon-button color="primary" (click)="copy(authData?.tokenIDRefresh)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>

          <div class="flex">
            <div class="texto" i18n-placeholder="@@front-login.result.tokenID.develop.pruposes">Develop pruposes</div>
            <button mat-icon-button color="primary" (click)="copyUsrVar()">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>

          <span i18n-placeholder="@@front-login.result.tokenID-refresh.expiration">
            tokenID refresh válido hasta: {{ authData?.tokenIDRefreshValidDate | date : "yyyy/MM/dd hh:mm a" }}
          </span>

          <div class="flex">
            <mat-form-field>
              <mat-label>Go To URL</mat-label>
              <input
                matInput
                required
                i18n-placeholder="@@front-login.result.url.placeholder"
                placeholder="Pon la URL a la que quieras navegar"
                [formControl]="urlBack"
                (ngModelChange)="size()"
              />
              <mat-hint>
                <span>La Url | size: {{ textSize }} sizeURI: {{ textSizeUrl }}</span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div class="flex f-gap--10px">
            <button mat-raised-button color="primary" (click)="navigate()">Navega</button>
            <button mat-raised-button (click)="clean()" color="warn" i18n-placeholder="@@front-login.result.delete-cookie">
              Borrar cookie
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}
