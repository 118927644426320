import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ActionsService, UserService } from '@app/core/services';

@Component({
  selector: 'howden-login-internal',
  templateUrl: './login-internal.component.html',
  styleUrls: ['./login-internal.component.scss']
})
export class LoginInternalComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private userSrv: UserService,
    private actionsSrv: ActionsService
  ) {
  }

  ngOnInit(): void {
    this.actionsSrv.changeLoading();
    this.userSrv.logout();
    this.readParams();
  }

  private readParams() {
    const goingDirect = this.activatedRoute.snapshot.url[0].path.toLocaleLowerCase() === 'login-dir';

    this.activatedRoute.queryParamMap.subscribe((data: ParamMap) => {
      this.userSrv.setGoDirect(goingDirect);
      this.userSrv.setInternalLogin();

      const url = this.readCallBack(data);

      if (url !== undefined) {
        this.userSrv.setCallBack(url);
      }

      this.goToProcess();
    });
  }

  private goToProcess(): void {
    this.route.navigateByUrl('/process');
  }

  private readCallBack(params: ParamMap): string | undefined {
    if (!params.has('callback')) {
      return '';
    }

    const loginParams: [string] = ['callback'];

    let url = params.get('callback')?.toString();

    // try to get another params
    const anotherParams: string[] = [];

    params.keys.forEach((key: string) => {
      if (!loginParams.find(x => x === key)) {
        anotherParams.push(`&${key}=${params.get(key)}`);
      }
    });

    if (anotherParams.length > 0) {
      anotherParams.forEach(param => {
        url = `${url}${param}`;
      });
    }

    return url;
  }
}
