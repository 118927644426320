import { Injectable } from '@angular/core';
import { IAppData } from '@app/core/models';
import { AuthenticationResult } from '@azure/msal-browser';
import { IAuthData } from '@howdeniberia/core-front';
import { IAuthenticationTicketDto, ITokenValidData, IUserPreferencesDto } from '@howdeniberia/core-front/lib/core/models';
import { StorageManagerService } from '../storage/storage-manager.service';
import { EvaluationsService } from '../util/evaluations.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _appData: IAppData = {} as IAppData;
  private _authdata: IAuthData = {} as IAuthData;
  private _evalToken!: ITokenValidData;

  constructor(private storageSrv: StorageManagerService, private valSrv: EvaluationsService) {
    this.readData();
  }

  get callBack(): string | null {
    return !this._appData?.callback || typeof this._appData?.callback === 'undefined' ? null : this._appData.callback;
  }

  get validCallBack(): boolean {
    return !!this.callBack;
  }

  get callBackStr(): string {
    return this._appData?.callback ? this._appData.callback : '';
  }

  get appData(): IAppData {
    return this._appData;
  }

  get authData(): IAuthData {
    return this._authdata;
  }

  readData(): void {
    this.readAppData();
    this.readAuthData();
  }

  setGoDirect(value: boolean) {
    this._appData.goDirect = value;
    this.saveAppData();
  }

  setCallBack(value?: string): void {
    this._appData.callback = value ? value : '';
    this.saveAppData();
  }

  saveAppData(): void {
    this.storageSrv.saveAppData(this._appData);
  }

  setInternalLogin() {
    this._appData.isInternal = true;
    this.saveAppData();
  }

  setExternalLogin() {
    this._appData.isInternal = false;
    this.saveAppData();
  }

  saveAuthData(): void {
    this.storageSrv.saveAuthData(this._authdata);
  }

  saveAuthenticationResult(value: AuthenticationResult): void {
    let username = value.account?.username;

    if (!username || username.length === 0) {
      if (value.account?.idTokenClaims && value.account?.idTokenClaims.emails && value.account?.idTokenClaims.emails.length > 0) {
        username = value.account?.idTokenClaims.emails[0];
      }
      if (!username || username.length === 0) {
        if (value.account?.idTokenClaims && value.account?.idTokenClaims['email']) {
          username = value.account?.idTokenClaims['email'] as string;
        }
      }
    }

    this._authdata.id = value.account?.localAccountId;
    this._authdata.account = username;
    this._authdata.name = value.account?.name;
    this._authdata.tokenAD = value.accessToken;
    this._authdata.tokenADValidDate = value.expiresOn;
    this.saveAuthData();
  }

  saveIdentificationResult(value: IAuthenticationTicketDto): void {
    this._authdata.tokenID = value.accessToken;
    this._authdata.tokenIDValidDate = new Date(value.accessTokenExpires);
    this._authdata.tokenIDRefresh = value.refreshToken;
    this._authdata.tokenIDRefreshValidDate = new Date(value.refreshTokenExpires);
    this._authdata.isSuperAdmin = value.isSuperAdmin;
    this._authdata.accessApp = value.accessApps;
    this._authdata.accessCompanies = value.accessCompanies;
    this._authdata.userPreferences = value.userPreferences;
    this.saveAuthData();
  }

  saveUserInfo(value: IAuthenticationTicketDto): void {
    this._authdata.id = value.userInfo?.userName;
    this._authdata.account = value.userInfo?.email;
    this._authdata.name = value.userInfo?.fullName;
    this.saveAuthData();
  }

  saveUserPreferences(value: IUserPreferencesDto): void {
    this._authdata.userPreferences = value;
    this.saveAuthData();
  }

  actualDataValidAD(): boolean {
    this._evalToken = this.evaluateAutentication(this._authdata);
    return this._evalToken.validAD;
  }

  actualDataValidID(): boolean {
    this._evalToken = this.evaluateAutentication(this._authdata);
    return this._evalToken.validID;
  }

  isAdmin(): boolean {
    if (!this._authdata?.name) {
      return false;
    }
    const admins = [''];
    const esAdmin = admins.includes(this._authdata.name);
    return esAdmin;
  }

  shouldSaveDataSource(): boolean {
    const existsActal = !this._authdata.userPreferences?.actualDataSource
      ? false
      : this._authdata.userPreferences?.actualDataSource !== '';
    return !existsActal;
  }

  shouldAskWhichDataSource(): boolean {
    const existsSeveral = !this._authdata.accessCompanies
      || this._authdata.accessCompanies?.map((x) => x.companyDataSource !== '').length > 1;

    return existsSeveral;
  }

  clearIDData(): void {
    this._authdata.id = undefined;
    this._authdata.account = undefined;
    this._authdata.name = undefined;
    this._authdata.tokenAD = undefined;
    this._authdata.tokenADValidDate = null;
    this.saveAuthData();
  }

  clearLocalData(): void {
    this.storageSrv.clearLocalStorage();
    this._appData = {} as IAppData;
  }

  logout(): void {
    this.storageSrv.clearCookies();
    this.storageSrv.clearLocalStorage();
    this._evalToken = {} as ITokenValidData;
    this._authdata = {} as IAuthData;
  }

  private readAppData(): void {
    const obj = this.storageSrv.readAppData();
    this._appData = obj ? obj : {
      callback: '',
      goDirect: false
    } as IAppData;
  }

  private readAuthData(): void {
    const obj = this.storageSrv.readAuthData();
    this._authdata = obj ? obj : ({} as IAuthData);
  }

  private evaluateAutentication(dataEval?: IAuthData): ITokenValidData {
    const result = { validAD: false, validID: false } as ITokenValidData;
    if (dataEval && dataEval.tokenAD && dataEval.tokenADValidDate) {
      result.validAD = this.valSrv.dateValidNow(dataEval.tokenADValidDate);
    }
    if (dataEval && dataEval.tokenID && dataEval.tokenIDValidDate) {
      result.validID = this.valSrv.dateValidNow(dataEval.tokenIDValidDate);
    }
    return result;
  }
}
