import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILoaderData } from './loader-data';

@Component({
  selector: 'howden-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  msg = '';

  constructor(@Inject(MAT_DIALOG_DATA) private readonly data: ILoaderData) {
  }

  ngOnInit(): void {
    this.msg = !this.data ? '' : this.data.msg;
  }
}
