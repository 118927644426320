<div class="error-page">
  <div class="flex error-container f-fd--column-md f-gap--25px f-jc--center f-ai--center">
    <div class="error-code text-right">
      <h1>KO</h1>
    </div>
    <div class="error-text text-left">
      <h2 i18n="@@front-login.forbidden.title">NO tienes permiso para acceder con {{ account }}</h2>
      <br />
      <p i18n="@@front-login.forbidden.body">Si quieres reintentarlo, puedes pulsar acceder</p>
      <button mat-raised-button (click)="retry()" color="warn" i18n="@@front-login.forbidden.action">
        Acceder
      </button>
    </div>
  </div>
</div>
