import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NOT_AUTHORIZED_ROUTING, NOT_FOUND_ROUTING } from '@howdeniberia/core-front';
import { ErrorComponent } from '@layout/error/error.component';
import { GrantedComponent } from '@pages/granted/granted.component';
import { LogOutComponent } from '@pages/log-out/log-out.component';
import { LoginInternalComponent } from '@pages/login-internal/login-internal.component';
import { ProcessComponent } from '@pages/process/process.component';
import { ResultComponent } from '@pages/result/result.component';
import { SucessComponent } from '@pages/sucess/sucess.component';
import { HowdenInformation } from './core/helpers';
import { FailedComponent } from './pages/failed/failed.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { UserPreferencesComponent } from './pages/user-preferences/user-preferences.component';

const routes: Routes = [
  { path: 'login', component: LoginInternalComponent },
  { path: 'login-dir', component: LoginInternalComponent },
  { path: 'process', component: ProcessComponent },
  { path: 'code', component: ProcessComponent },
  { path: 'error', component: ProcessComponent },
  { path: 'granted', component: GrantedComponent },
  { path: 'logout', component: LogOutComponent },
  { path: 'sucess', component: SucessComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: 'preferences', component: UserPreferencesComponent },
  { path: 'login-failed', component: FailedComponent },
  {
    path: 'result',
    title: `result ${new HowdenInformation().version}`,
    component: ResultComponent
  },
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  { path: 'err/:msg', component: ErrorComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
