import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdTokenClaimsWithPolicyId } from '@app/core/models';
import { LoginService, UserService } from '@app/core/services';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, PopupRequest, PromptValue, RedirectRequest } from '@azure/msal-browser';
import { environment } from '@environment/environment';
import { HowdenLogService, isHowdenGroupB2CEnvironment } from '@howdeniberia/core-front';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'howden-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private route: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userSrv: UserService,
    private logingSrv: LoginService,
    private logSrv: HowdenLogService
  ) {
  }

  ngOnInit(): void {
    if (!this.userSrv.actualDataValidAD()) {
      this.loginMicrosoft();
    } else {
      this.route.navigateByUrl('/granted');
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  loginMicrosoft(): void {
    if (this.logingSrv.anyADActiveAccount()) {
      this.waitFormMSToken().subscribe(res => {
        if (!res) {
          this.waitForMSLog();
        }
      });
    } else {
      this.waitForMSLog();
    }
  }

  private waitFormMSToken(): Observable<boolean> {
    return new Observable(observer => {
      this.logingSrv.getTokenSilent().pipe(
        tap({
          next: (payload) => {
            this.logSrv.Info(`msal get token sucess`, payload);
            this.logSrv.Info(`msal access token`, payload.accessToken);
            this.processAuthentication(payload);
            observer.next(true);
            observer.complete();
          },
          error: (err: unknown) => {
            this.logSrv.Error(`msal get token error `, err);
            this.logingSrv.getToken().subscribe({
              next: () => {
                observer.next(false);
                observer.complete();
              },
              error: () => this.logSrv.Error(`msal get token error `, err)
            });
          }
        })
      ).subscribe();
    });
  }

  private waitForMSLog(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      ).subscribe({
        next: (result: EventMessage) => {
          const isAuthResult = (ar: any): ar is AuthenticationResult => (ar as AuthenticationResult).account !== undefined;

          if (result && result.payload && isAuthResult(result.payload)) {
            this.logSrv.Info(`msalBroadcast sucess ${result.eventType} - access token`, result?.payload?.accessToken);

            const payload = result.payload as AuthenticationResult;
            const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

            /**
            * Below we are checking if the user is returning from the reset password flow.
            * If so, we will ask the user to reauthenticate with their new password.
            * If you do not want this behavior and prefer your users to stay signed in instead,
            * you can replace the code below with the same pattern used for handling the return from
            * profile edit flow (see above ln. 74-92).
            */
            const isB2cEnv = isHowdenGroupB2CEnvironment(environment.zone);
            if (isB2cEnv && (this.isResetPasswordFlow(idtoken.acr) || this.isResetPasswordFlow(idtoken.tfp))) {
              const signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
                authority: environment.openid!.policies!.authorities.signUpSignIn.authority,
                scopes: [...environment.openid!.apiConfig.scopes],
                prompt: PromptValue.LOGIN // force user to reauthenticate with their new password
              };

              this.logingSrv.loginMicrosoft(signUpSignInFlowRequest);
            } else {
              this.processAuthentication(payload);
            }
          }
        },
        error: (err: unknown) => this.logSrv.Error('msalBroadcast failed', err)
      });

    this.logingSrv.loginMicrosoft().subscribe();
  }

  private processAuthentication(payload: AuthenticationResult): void {
    const idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
    const hasAccount = isHowdenGroupB2CEnvironment(environment.zone)
      ? this.isSignUpSignInFlow(idtoken.acr) || this.isSignUpSignInFlow(idtoken.tfp)  // Azure B2C
      : (typeof payload.account !== 'undefined' && payload.account !== null);         // Microsoft Entra

    if (hasAccount) {
      this.authService.instance.setActiveAccount(payload.account);
      this.userSrv.saveAuthenticationResult(payload);
      this.route.navigateByUrl('/granted');
    }
  }

  private isSignUpSignInFlow(flow: string | undefined): boolean {
    if (!flow) {
      return false;
    }
    return flow.localeCompare(environment.openid!.policies!.names.signUpSignIn, undefined, { sensitivity: 'base' }) === 0;
  }

  private isResetPasswordFlow(flow: string | undefined): boolean {
    if (!flow) {
      return false;
    }
    return flow.localeCompare(environment.openid!.policies!.names.resetPassword, undefined, { sensitivity: 'base' }) === 0;
  }
}
